<template>
<h1>Events</h1>

<hr>


<!-- <h3>Gratitude Day</h3>
Saturday, November 2nd, 2024<br>
In-person & Online<br> -->
<br>
<img v-bind:src="'GDFlyer.png'" alt="Gratitude Day 2024 Flier">

<button onclick="window.location.href='https://forms.gle/maRVEcQJY3jeHnDm7'" class="button-46">
      Click Here to Register!
</button>
<br>
<hr>

</template>

<style scoped>
 img {
   width: 80vw;
   max-width: 800px
 }

h2, h3{ 
  text-align: center;
}

a {
  font-size: 24px;
}

button {
  width: 75%;
  height: 50px;
  font-size: large;
}

</style>